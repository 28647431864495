import React from 'react';

const Loader = () => {

  return (
    <div id="overlay">
    <div className="spinner"><img src="../assets/images/loader.png" /></div>
  </div>
  );
};

export default Loader;
