import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import DigitalMarketing from '../utils/DigitalMarketing';
import service1 from '../assets/img/Services/graphicdesign.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Card from './Card';
import { Helmet } from 'react-helmet';

export default function GraphicDesignService(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return(
      <>
      <Helmet>
        <title>IT Power Systems LLC - Digital Marketing</title>
        <meta name="description" content="Maximize your business growth with comprehensive digital marketing services. From SEO to PPC, we implement strategies that deliver measurable results - Digital Marketing" />
      </Helmet>
      <section className="inner-banner digital-marketing-banner">
      <div className="banner-content">
        <div className="container">
          <div className="row banner-b justify-content-center">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
              <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Digital Marketing</span></h2>
              <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Digital Marketing</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="about-sec inner-about inner-serv">
        <div className="container">
          <div className="row justify-content-center gy-4">
            <div className="col-xl- col-lg-8 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
              <div>
                <img className='img-fluid' src='assets/images/digital-marketing-image.jpg' />
              </div>
              <div className="content"> 
                <h2 className="theme-h2">Digital Marketing</h2>
                <p>IT Power Systems LLC offers robust Digital Marketing services designed to enhance your brand’s visibility and engagement online. Our expert team employs a range of strategies, including SEO, PPC, social media marketing, and email campaigns, to ensure comprehensive coverage and maximum impact. We use data-driven insights and industry best practices to create campaigns that not only attract but also convert potential customers.</p>
                <p>Our approach is strategic and focused on long-term growth. We begin by understanding your business objectives and target audience, then develop a customized plan that aligns with your goals. Whether aiming to increase brand awareness, drive traffic, or boost sales, our digital marketing solutions are crafted to deliver sustainable success.</p>
                <h2 className="theme-h2">Services We Provide:</h2>
                <ul>
                  <li>Pay-Per-Click (PPC) Advertising</li>
                  <li>Email Marketing Campaigns</li>
                  <li>Content Marketing</li>
                  <li>Social Media Advertising</li>
                  <li>Analytics and Reporting</li>
                </ul>
                <h2 className="theme-h2">Why We Are the Best: </h2>
                <p>We combine creativity with advanced analytics to deliver exceptional digital marketing results. Our team stays ahead of the latest trends to ensure your campaigns are both innovative and effective. By understanding your business and audience, we create personalized strategies that yield high ROI and foster growth.</p>
                <p>Call to Action: Ready to take your digital marketing to the next level? Contact IT Power Systems LLC today and let’s create a strategy that drives results</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
              <div className="ser-box">
                <img src="assets/images/services-details-icon-01.png" alt="logo design service" className="img-fluid"/>
                <h2 className="theme-h2">Don't hesitate to contact us</h2>
                <p>At our IT solution company, we are committed to exceptional</p>
                <Link className="theme-btn" to="/contact-us">Get In Touch </Link>
               </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing py-5">
        <div className="container">
          <div className="row">
            <div className='text-center pb-5 text-white'>
              <h1 className='pb-2' style={{ fontWeight: 'bold' }}>Choose your right plan!</h1>
              <p className='text-white'>Select from best plan, ensuring a perfect match, Need more or less?</p>
              <p className='text-white pb-2'>Customize Your subscription for a seamless fit!</p>
            </div>
            {
              DigitalMarketing?.map((obj) => { return <Card data={obj} /> })   // store data in myPrices map to card 
            }
          </div>
        </div>
      </section>
      <section className="ctabanner" >
          <div className="container">
              <div className="box-cta" data-aos="fade-up" data-aos-duration="3000">
              <div className="row justify-content-center align-items-center">
                  <div className="col-12 col-sm-12 col-md-11 col-lg-12 col-xl-11 col-xxl-11">
                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="ctatext">
                      <p>IT Power Systems LLC,</p>
                      <h3>Your business can reach its full potential.</h3>
                      </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="calllsit">
                          <div className=" marginleft">
                          <Link className="theme-btn" to="/contact-us">Contact Us </Link>
                          </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </div>
      </section>
      </>
    );
}