let EcommerceWebsite = [
    {
        plan: "Basic",
        price: "$1,500",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Setting up a customized e-commerce platform.",
                isEnabled: true
            },
            {
                name: "Adding up to 50 products with descriptions and images.",
                isEnabled: true
            },
            {
                name: "Integrating basic payment gateway (Stripe).",
                isEnabled: true
            },
            {
                name: "Basic SEO for product listings.",
                isEnabled: true
            },
            {
                name: "Customizing the checkout process for better user experience.",
                isEnabled: false
            },
            {
                name: "Custom design with basic features.",
                isEnabled: false
            },
            {
                name: "Advanced inventory management to track stock levels.",
                isEnabled: false
            },
            {
                name: "In-depth reporting and analytics for sales and performance.",
                isEnabled: false
            },
            {
                name: "Advanced sales, traffic, and customer behavior reports",
                isEnabled: false
            },
            {
                name: "1 year of e-commerce support and updates",
                isEnabled: false
            },
            {
                name: "Customizing shipping and tax rules for different regions.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Standard",
        price: "$3,000",
        recommended: true,
        button: "Get Started",
        features: [
            {
                name: "Setting up a customized e-commerce platform.",
                isEnabled: true
            },
            {
                name: "Adding up to 100 products with descriptions and images.",
                isEnabled: true
            },
            {
                name: "Integrating multiple payment gateways.",
                isEnabled: true
            },
            {
                name: "Basic SEO for product listings.",
                isEnabled: true
            },
            {
                name: "Customizing the checkout process for better user experience.",
                isEnabled: true
            },
            {
                name: "Custom design with basic features.",
                isEnabled: true
            },
            {
                name: "Advanced inventory management to track stock levels.",
                isEnabled: true
            },
            {
                name: "In-depth reporting and analytics for sales and performance.",
                isEnabled: false
            },
            {
                name: "Advanced sales, traffic, and customer behavior reports",
                isEnabled: false
            },
            {
                name: "1 year of e-commerce support and updates",
                isEnabled: false
            },
            {
                name: "Customizing shipping and tax rules for different regions.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Premium",
        price: "$6,000",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Setting up a fully customized e-commerce platform.",
                isEnabled: true
            },
            {
                name: "Adding unlimited products with descriptions and images.",
                isEnabled: true
            },
            {
                name: "Integrating multiple payment gateways.",
                isEnabled: true
            },
            {
                name: "Basic SEO for product listings.",
                isEnabled: true
            },
            {
                name: "Customizing the checkout process for better user experience.",
                isEnabled: true
            },
            {
                name: "Custom design with advanced features.",
                isEnabled: true
            },
            {
                name: "Advanced inventory management with automated stock updates.",
                isEnabled: true
            },
            {
                name: "In-depth reporting and analytics for sales and performance.",
                isEnabled: true
            },
            {
                name: "Advanced sales, traffic, and customer behavior reports",
                isEnabled: true
            },
            {
                name: "1 year of e-commerce support and updates",
                isEnabled: true
            },
            {
                name: "Customizing shipping and tax rules for different regions.",
                isEnabled: true
            },
        ]
    }
]

export default EcommerceWebsite;