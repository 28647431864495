let websiteDevelopmentPrices = [
    {
        plan: "Basic",
        price: "$600",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Responsive Design accessible on all devices.",
                isEnabled: true
            },
            {
                name: "05 pages to cover your business needs.",
                isEnabled: true
            },
            {
                name: "Basic SEO for better visibility.",
                isEnabled: true
            },
            {
                name: "A form for users to reach out to you.",
                isEnabled: true
            },
            {
                name: "Easily update and manage your website content.",
                isEnabled: false
            },
            {
                name: "Sell products directly from your website.",
                isEnabled: false
            },
            {
                name: "Custom design tailored to your brand.",
                isEnabled: false
            },
            {
                name: "Ongoing updates and maintenance for one year.",
                isEnabled: false
            }, 
        ]
    },
    {
        plan: "Standard",
        price: "$1,200",
        recommended: true,
        button: "Get Started",
        features: [
            {
                name: "Responsive Design accessible on all devices.",
                isEnabled: true
            },
            {
                name: "10 pages to cover your business needs.",
                isEnabled: true
            },
            {
                name: "Basic SEO for better visibility.",
                isEnabled: true
            },
            {
                name: "A form for users to reach out to you.",
                isEnabled: true
            },
            {
                name: "Easily update and manage your website content.",
                isEnabled: true
            },
            {
                name: "Sell products directly from your website.",
                isEnabled: true
            },
            {
                name: "Custom design tailored to your brand.",
                isEnabled: true
            },
            {
                name: "Ongoing updates and maintenance for one year.",
                isEnabled: false
            }, 
        ]
    },
    {
        plan: "Premium",
        price: "$2,500",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Responsive Design accessible on all devices.",
                isEnabled: true
            },
            {
                name: "Unlimited pages to cover all your business needs.",
                isEnabled: true
            },
            {
                name: "Advanced SEO strategies for higher rankings.",
                isEnabled: true
            },
            {
                name: "A form for users to reach out to you.",
                isEnabled: true
            },
            {
                name: "Easily update and manage your website content.",
                isEnabled: true
            },
            {
                name: "Sell products directly from your website.",
                isEnabled: true
            },
            {
                name: "Custom design tailored to your brand.",
                isEnabled: true
            },
            {
                name: "Ongoing updates and maintenance for one year.",
                isEnabled: true
            },
            
        ]
    }
]

export default websiteDevelopmentPrices;