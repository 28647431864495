import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FloatingWhatsApp } from "react-floating-whatsapp";
const Header = () => {
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        setScroll(window.scrollY);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const calculateTimeLeft = () => {
        const halloweenDate = new Date('2024-10-31T00:00:00'); // Set Halloween date and time
        const now = new Date();
        const difference = halloweenDate - now;

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer); // Cleanup the interval on component unmount
    }, []);

  return (
    <>
      {/* <div id="portrait-warnning" class="alert-warnning">
          <p>For an optimal experience please<br/> rotate your device to portrait mode</p>
      </div> */}
      <div>
      <h1>Welcome to My Website</h1>
        <FloatingWhatsApp
            phoneNumber="+15716220760" // Your WhatsApp number
            accountName="Support"     // Name that will show in the chat header
            avatar="assets/images/whats-avatar.jpg" // Optional avatar image URL
            statusMessage="Typically replies within 5 minutes" // Status message shown in the chat
            chatMessage="Hello! How can we help you today?"   // Initial message in the chat
            allowClickAway={true}     // Allows closing the chat by clicking outside
            notification={true}       // Shows a notification bubble
            notificationSound={true}  // Plays a notification sound
        />
        </div>
      <header className={`main-header ${scroll >= 100 ? 'scroll' : ''}`}>
              <div className="top-head">
                  <div className="container">
                      <div className="display-between top-grid">
                          <div className="gridd">
                              <ul className="display-start">
                                  {/* <li><a href="/contact-us"><i className="fa-solid fa-comments"></i> live chat</a></li> */}
                                  <li><a href="https://wa.me/+15716220760" target='_blank'>
                                      <i class="fa-brands fa-whatsapp"></i> +1 571-622-0760</a>
                                  </li>
                                  <li><a href="tel:+1 828-738-2210" target='_blank'>
                                      <i className="fa-solid fa-phone-volume"></i> +1 828-738-2210</a>
                                  </li>
                              </ul>
                          </div>
                          {/* <div className="gridd">
        <Link className="talk-btn" to="/contact-us">let's talk <i
                className="fa-light fa-arrow-right-long"></i></Link>
    </div> */}
                          {/* <div className="gridd marque">
        <ul>
            <li>
                 <strong>
                    {timeLeft.days || 0} D : {timeLeft.hours || 0} : {timeLeft.minutes || 0} M : {timeLeft.seconds || 0}
                </strong>
                <strong>
                Limited-time offer – {timeLeft.days || 0} days left to save!
                </strong>
            </li>
            <li>
                <marquee class="" >
                Only Available Until Halloween!
                </marquee>
            </li>
        </ul>
    </div> */}
                          <div className="gridd">
                              <ul className="display-end">
                                  <li><Link to="https://www.instagram.com/itpowersystems/" target='_blank'><i
                                      className="fa-brands fa-instagram"></i></Link></li>
                                  <li><Link to="https://www.facebook.com/profile.php?id=61559295691668" target='_blank'><i
                                      className="fa-brands fa-facebook-f"></i></Link></li>
                                  <li><Link to="https://www.linkedin.com/company/itpowersystems/?viewAsMember=true" target='_blank'><i className="fa-brands fa-linkedin-in"></i></Link></li>
                                  {/* <li><Link to=""><i className="fa-brands fa-twitter"></i></Link></li> */}
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <nav className="navbar navbar-expand-lg">
                  <div className="container">
                      <Link to="/" className="navbar-brand"><img className="img-fluid" src="assets/images/IT Power Systems Logo - White.png" alt="IT Power Systems" /></Link>
                      <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                          aria-expanded="false" aria-label="Toggle navigation">
                          <span className="navbar-toggler-icon"></span>
                      </button>
                      <div className="collapse navbar-collapse" id="navbarSupportedContent">
                          <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                              <li className="nav-item">
                                  <Link className="nav-link" aria-current="page" to="/">Home</Link>
                              </li>
                              <li className="nav-item">
                                  <Link className="nav-link" to="/about-us">about us</Link>
                              </li>
                              <li className="nav-item dropdown">
                                  <Link className="nav-link dropdown-toggle" role="button"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      services
                                  </Link>
                                  <ul className="dropdown-menu">
                                      <li><Link className="dropdown-item" to="/graphic-design">
                                          <span><img className="img-fluid" src="assets/images/service-icon/logo-icon.png"
                                              alt="logo desig" /></span>
                                          <span>Graphic Design</span></Link></li>
                                      <li><Link className="dropdown-item" to="/web-development">
                                          <span><img className="img-fluid" src="assets/images/service-icon/illustration-icon.png"
                                              alt="Web development" /></span>
                                          <span>Web development</span> </Link></li>
                                      <li><Link className="dropdown-item" to="/social-media-marketing">
                                          <span><img className="img-fluid" src="assets/images/service-icon/web-development-icon.png"
                                              alt="Social Media Marketing" /></span>
                                          <span>Social Media Marketing</span></Link></li>
                                      <li><Link className="dropdown-item" to="/uiux-design">
                                          <span><img className="img-fluid" src="assets/images/service-icon/mobile-app-icon.png"
                                              alt="app design" /></span>
                                          <span>UIUX Design</span></Link></li>
                                      <li><Link className="dropdown-item" to="/digital-marketing-service">
                                          <span><img className="img-fluid"
                                              src="assets/images/service-icon/digital-marketing-icon.png" alt="digital marketing" /></span>
                                          <span>Digital marketing</span></Link></li>
                                      <li><Link className="dropdown-item" to="/search-engine-optimization">
                                          <span><img className="img-fluid" src="assets/images/service-icon/seo-icon.png"
                                              alt="SEO services" /></span>
                                          <span>SEO services</span> </Link></li>
                                      <li><Link className="dropdown-item" to="/ecommerce-web">
                                          <span><img className="img-fluid" src="assets/images/service-icon/ecommerce-icon.png"
                                              alt="ecommerce web solutions" /></span>
                                          <span>Ecommerce web solutions</span></Link></li>
                                      {/* <li><Link className="dropdown-item" to="/content-writer">
            <span><img className="img-fluid" src="assets/images/service-icon/copywriting-icon.png"
                    alt="Content Writing"/></span>
            <span>Content Writing</span></Link></li> */}
                                      <li><Link className="dropdown-item" to="/software-service">
                                          <span><img className="img-fluid" src="assets/images/service-icon/mobile-app-icon.png"
                                              alt="Software Development" /></span>
                                          <span>Software Development</span></Link></li>
                                      <li><Link className="dropdown-item" to="/mobile-app-service">
                                          <span><img className="img-fluid" src="assets/images/service-icon/copywriting-icon.png"
                                              alt="Mobile App Development" /></span>
                                          <span>Mobile App Development</span></Link></li>
                                  </ul>
                              </li>
                              <li className="nav-item">
                                  <Link className="nav-link" to="/our-portfolio">Our portfolio</Link>
                              </li>
                              <li className="nav-item">
                                  <Link className="nav-link" to="/our-blogs">Our Blogs</Link>
                              </li>
                              <li className="nav-item">
                                  <Link className="nav-link" to="/contact-us">contact us</Link>
                              </li>
                          </ul>
                          <form className="d-flex">
                              <Link to="/contact-us" className="theme-btn" role="button">let's talk</Link>
                          </form>
                      </div>
                  </div>
              </nav>
          </header></>

  );
};

export default Header;
