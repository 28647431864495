import React, { useState, useEffect } from 'react'; // Correct import from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import ScrollToTop from "./component/ScrollToTop";
import Header from "./component/Header";
import Home from "./component/Home";
import Services from "./component/Services";
import AboutUs from "./component/AboutUs";
import CaseStudies from "./component/CaseStudies";
import Portfolio from "./component/Portfolio";
import OurClients from "./component/OurClients";
import ContactUs from "./component/ContactUs";
import Footer from "./component/Footer";
import GraphicDesignService from "./component/GraphicDesignService";
import SocialMediaMarketingService from "./component/SocialMediaMarketingService";
import ECommerceWebsiteService from "./component/ECommerceWebsiteService";
import UIUXDesignService from "./component/UIUXDesignService";
import WebDevelopment from "./component/WebDevelopment";
import DigitalMarketingService from "./component/DigitalMarketingService";
import Privacy from "./component/Privacy";
import Terms from "./component/Terms";
import ContentWriter from "./component/ContentWriter";
import SeoService from "./component/SeoService";
import SoftwareDevelopment from "./component/SoftwareDevelopment";
import MobileAppServices from "./component/MobileAppServices";
import OurBlogs from "./component/OurBlogs";
import BlogDetail from "./component/BlogDetail";
import DigitalMarketingBlogDetail from "./component/DigitalMarketingBlogDetail";
import UIUXBlogDetail from "./component/UIUXBlogDetail";
import MobileAppBlogDetail from "./component/MobileAppBlogDetail";
import SocialMediaBlogDetail from "./component/SocialMediaBlogDetail";
import GraphicDesignBlogDetail from "./component/GraphicDesignBlogDetail";
import MobileAppBlogDetails from "./component/MobileApplicationBlogDetails";
import EcommerceWebBlogdetail from "./component/ecommerce-web-blogdetail";
import MobileAppBlog3Details from "./component/mobile-app-blog-details";
import UIUXMobileBlogsDetails from "./component/uiux-mobile-app-blog-details";
import ContentMarketingBlogsDetails from "./component/Content-marketing-blog-details";
import SocialMediaMarketingBlogsDetails from "./component/social-media-marketing-Blog-Detail";
import Thankyou from "./component/Thankyou";
import Loader from "./component/Loader";

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      // Ensure the loader shows for at least 3 seconds
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    window.addEventListener('load', handleLoad);

    // Fallback in case the load event is not triggered
    const timeout = setTimeout(handleLoad, 500);

    return () => {
      window.removeEventListener('load', handleLoad);
      clearTimeout(timeout);
    };
  }, []);


  useEffect(() => {
    // Ensure Tawk_API is available globally
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    // Create a script element
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/669ea20632dca6db2cb3a15a/default';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    // Define the Tawk_API.onLoad callback to maximize the chat widget
    script.onload = () => {
      if (window.Tawk_API && window.Tawk_API.maximize) {
        window.Tawk_API.onLoad = function() {
          window.Tawk_API.maximize();
        };
      }
    };

    // Add script to the document
    document.head.appendChild(script);

    // Cleanup the script when component unmounts
    return () => {
      document.head.removeChild(script);
      delete window.Tawk_API;
      delete window.Tawk_LoadStart;
    };
  }, []);

  return(
  <Router> 
      <Header />
      {loading && <Loader />}
      <ScrollToTop />
      <Routes>
        <Route path="/Services" element={<Services />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/CaseStudies" element={<CaseStudies />}></Route>
        <Route path="/our-portfolio" element={<Portfolio />}></Route>
        <Route path="/OurClients" element={<OurClients />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/graphic-design" element={<GraphicDesignService />}></Route>
        <Route path="/social-media-marketing" element={<SocialMediaMarketingService />}></Route>
        <Route path="/ecommerce-web" element={<ECommerceWebsiteService />}></Route>
        <Route path="/uiux-design" element={<UIUXDesignService />}></Route>
        <Route path="/web-development" element={<WebDevelopment />}></Route>
        <Route path="/digital-marketing-service" element={<DigitalMarketingService />}></Route>
        <Route path="/mobile-app-service" element={<MobileAppServices />}></Route>
        <Route path="/software-service" element={<SoftwareDevelopment />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/Terms" element={<Terms />}></Route>
        <Route path="/content-writer" element={<ContentWriter />}></Route>
        <Route path="/search-engine-optimization" element={<SeoService />}></Route>
        <Route path="/our-blogs" element={<OurBlogs />}></Route>
        <Route path="/blog-detail" element={<BlogDetail />}></Route>
        <Route path="/digital-marketing-BlogDetail" element={<DigitalMarketingBlogDetail />}></Route>
        <Route path="/uiux-blogdetail" element={<UIUXBlogDetail />}></Route>
        <Route path="/mobile-app-blogdetail" element={<MobileAppBlogDetail />}></Route>
        <Route path="/social-media-blogdetail" element={<SocialMediaBlogDetail />}></Route> 
        <Route path="/graphic-design-blogdetail" element={<GraphicDesignBlogDetail />}></Route>
        <Route path="/app-mobile-blogdetail" element={<MobileAppBlogDetails />}></Route>
        <Route path="/ecommerce-web-blogdetail" element={<EcommerceWebBlogdetail />}></Route>
        <Route path="/mobile-app-blog-details" element={<MobileAppBlog3Details />}></Route>
        <Route path="/uiux-mobile-app-blog-details" element={<UIUXMobileBlogsDetails />}></Route>
        <Route path="/Content-marketing-blog-details" element={<ContentMarketingBlogsDetails />}></Route>
        <Route path="/social-media-marketing-Blog-Detail" element={<SocialMediaMarketingBlogsDetails />}></Route>
        <Route path="/thank-you" element={<Thankyou />}></Route>
        <Route path="/" element={<Home />}></Route>   
      </Routes>   
      <Footer />
  </Router>
);
}
export default App;
