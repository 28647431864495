let SoftwareDevelopments = [
    {
        plan: "Basic",
        price: "$5,000",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Basic software tailored to your needs.",
                isEnabled: true
            },
            {
                name: "Design and management of a basic database.",
                isEnabled: true
            },
            {
                name: "Basic UI/UX design without prototype.",
                isEnabled: true
            },
            {
                name: "Implementation of basic security measures.",
                isEnabled: true
            },
            {
                name: "Comprehensive documentation with training.",
                isEnabled: false
            },
            {
                name: "Integrating APIs for added functionality.",
                isEnabled: false
            },
            {
                name: "Ensuring software works across multiple platforms.",
                isEnabled: false
            },
            {
                name: "Regular updates and ongoing maintenance.",
                isEnabled: false
            },
            {
                name: "24/7 support for any issues or updates.",
                isEnabled: false
            },
            {
                name: "Custom reporting features for data analysis.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Standard",
        price: "$10,000",
        recommended: true,
        button: "Get Started",
        features: [
            {
                name: "Advanced software tailored to your needs.",
                isEnabled: true
            },
            {
                name: "Design and management of a complex database.",
                isEnabled: true
            },
            {
                name: "Advanced UI/UX design with prototype.",
                isEnabled: true
            },
            {
                name: "Implementation of advanced security measures.",
                isEnabled: true
            },
            {
                name: "Comprehensive documentation with training.",
                isEnabled: true
            },
            {
                name: "Integrating APIs for added functionality.",
                isEnabled: true
            },
            {
                name: "Ensuring software works across multiple platforms.",
                isEnabled: true
            },
            {
                name: "Regular updates and ongoing maintenance.",
                isEnabled: false
            },
            {
                name: "24/7 support for any issues or updates.",
                isEnabled: false
            },
            {
                name: "Custom reporting features for data analysis.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Premium",
        price: "$20,000",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Advanced software tailored to your needs.",
                isEnabled: true
            },
            {
                name: "Design and management of a complex database.",
                isEnabled: true
            },
            {
                name: "Advanced UI/UX design with prototype.",
                isEnabled: true
            },
            {
                name: "Implementation of advanced security measures.",
                isEnabled: true
            },
            {
                name: "Comprehensive documentation with training.",
                isEnabled: true
            },
            {
                name: "Integrating APIs for added functionality.",
                isEnabled: true
            },
            {
                name: "Ensuring software works across multiple platforms.",
                isEnabled: true
            },
            {
                name: "Regular updates and ongoing maintenance.",
                isEnabled: true
            },
            {
                name: "24/7 support for any issues or updates.",
                isEnabled: true
            },
            {
                name: "Custom reporting features for data analysis.",
                isEnabled: true
            },
        ]
    }
]

export default SoftwareDevelopments;