import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import PortfolioSec from "./PortfolioSec";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Thankyou(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return (
      <>
      <section className="inner-banner">
        <div className="banner-content">
          <div className="container">
            <div className="row banner-b justify-content-center">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Thank You</span></h2>
                <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Thank You</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    <section className="sec-tabs-main orange-thanks-box">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7 col-xxl-7">
                    <div className="">
                        <div className="sec-title">
                            <img className="img-fluid e-img" src="assets/images/thankyou.jpg" alt="Thank You"/>
                            <h2 className="theme-h2">Thank <span>You!</span></h2>
                            <h3 className="theme-h4">Your form was successfully submitted.</h3>
                            <p>Thank you for submitting your form. Our representative will contact you soon to discuss
                                the next steps and move the process forward. We appreciate your interest and look
                                forward to working with you. For any immediate assistance, feel free to reach out to us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        
        </>
    
    );
}