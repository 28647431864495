import React from "react";
export default function(){
    return(
      <>
      <section className="work-sec portfolioboxes -pricing port-new port_new_portfolio_page">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-11 col-lg-11">
                <div className="title">
                  <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000">Our <span>Portfolio</span></h2>
                  <p data-aos="fade-left" data-aos-duration="3000" className="pt-2">Explore our diverse portfolio and see how IT Power Systems LLC has empowered businesses and startups with innovative digital solutions. From stunning websites to engaging social media campaigns, our work speaks for itself. Discover the creativity, precision, and impact behind every project.</p>
                </div>
                <div className="tabs-btns" data-aos="zoom-in" data-aos-duration="3000">
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="pills-Website-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-Website" type="button" role="tab" aria-controls="pills-Website"
                        aria-selected="true">Website</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-Logo-Design-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-Logo-Design" type="button" role="tab"
                        aria-controls="pills-Logo-Design" aria-selected="false">Branding</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-ecommerce-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-ecommerce" type="button" role="tab"
                        aria-controls="pills-ecommerce" aria-selected="false">UIUX Design</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-motion-graphic-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-motion-graphic" type="button" role="tab"
                        aria-controls="pills-motion-graphic" aria-selected="false">Social Media Design</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent" data-aos="zoom-in" data-aos-duration="3000">
              <div className="tab-pane fade" id="pills-Logo-Design" role="tabpanel" aria-labelledby="pills-Logo-Design-tab"
                tabindex="0">
                <ul className="tabs tab-branding mportt">
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/Branding/01.webp">
                      <figure>
                        <img src="assets/images/Branding/01.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/Branding/02.webp">
                      <figure>
                        <img src="assets/images/Branding/02.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/Branding/03.webp">
                      <figure>
                        <img src="assets/images/Branding/03.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/Branding/04.webp">
                      <figure>
                        <img src="assets/images/Branding/04.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/Branding/05.webp">
                      <figure>
                        <img src="assets/images/Branding/05.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/Branding/06.jpg">
                      <figure>
                        <img src="assets/images/Branding/06.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/Branding/07.jpg">
                      <figure>
                        <img src="assets/images/Branding/07.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/Branding/08.png">
                      <figure>
                        <img src="assets/images/Branding/08.png" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/Branding/09.png">
                      <figure>
                        <img src="assets/images/Branding/09.png" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/Branding/10.jpg">
                      <figure>
                        <img src="assets/images/Branding/10.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/Branding/11.png">
                      <figure>
                        <img src="assets/images/Branding/11.png" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/Branding/12.jpg">
                      <figure>
                        <img src="assets/images/Branding/12.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>

                </ul>
              </div>
              <div className="tab-pane fade  show active" id="pills-Website" role="tabpanel"
                aria-labelledby="pills-Website-tab" tabindex="0">
                <ul className="tabs tab-webdesign mportt current">
                  <li className="port">
                    <a href="assets/images/portfolio/ecommerce/inner/1.webp" data-fancybox="port"
                      className="column website-pack-preview fancybox">
                      <span className="card">
                        <img className="img_porr_front" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-1-1.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-1-2.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-1-3.webp" />
                      </span>
                    </a>
                  </li>
                  <li className="port">
                    <a href="assets/images/portfolio/ecommerce/inner/2.jpg" data-fancybox="port"
                      className="column website-pack-preview fancybox">
                      <span className="card">
                        <img className="img_porr_front" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-2-1.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-2-2.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-2-3.webp" />
                      </span>
                    </a>
                  </li>
                  <li className="port">
                    <a href="assets/images/portfolio/ecommerce/inner/3.webp" data-fancybox="port"
                      className="column website-pack-preview fancybox">
                      <span className="card">
                        <img className="img_porr_front" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-3-1.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-3-2.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-3-3.webp" />
                      </span>
                    </a>
                  </li>
                  <li className="port">
                    <a href="assets/images/portfolio/ecommerce/inner/4.webp" data-fancybox="port"
                      className="column website-pack-preview fancybox">
                      <span className="card">
                        <img className="img_porr_front" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-4-1.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-4-2.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-4-3.webp" />
                      </span>
                    </a>
                  </li>
                  <li className="port">
                    <a href="assets/images/portfolio/ecommerce/inner/5.webp" data-fancybox="port"
                      className="column website-pack-preview fancybox">
                      <span className="card">
                        <img className="img_porr_front" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-5-1.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-5-2.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-5-3.webp" />
                      </span>
                    </a>
                  </li>
                  <li className="port">
                    <a href="assets/images/portfolio/ecommerce/inner/6.webp" data-fancybox="port"
                      className="column website-pack-preview fancybox">
                      <span className="card">
                        <img className="img_porr_front" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-6-1.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-6-2.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-6-3.webp" />
                      </span>
                    </a>
                  </li>
                  <li className="port">
                    <a href="assets/images/portfolio/ecommerce/inner/7.webp" data-fancybox="port"
                      className="column website-pack-preview fancybox">
                      <span className="card">
                        <img className="img_porr_front" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-7-1.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-7-2.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-7-3.webp" />
                      </span>
                    </a>
                  </li>
                  <li className="port">
                    <a href="assets/images/portfolio/ecommerce/inner/8.webp" data-fancybox="port"
                      className="column website-pack-preview fancybox">
                      <span className="card">
                        <img className="img_porr_front" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-8-1.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-8-2.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-8-3.webp" />
                      </span>
                    </a>
                  </li>
                  <li className="port">
                    <a href="assets/images/portfolio/ecommerce/inner/9.webp" data-fancybox="port"
                      className="column website-pack-preview fancybox">
                      <span className="card">
                        <img className="img_porr_front" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-9-1.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-9-2.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-9-3.webp" />
                      </span>
                    </a>
                  </li>
                  {/* <li className="port">
                    <a href="assets/images/portfolio/ecommerce/inner/10.webp" data-fancybox="port"
                      className="column website-pack-preview fancybox">
                      <span className="card">
                        <img className="img_porr_front" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-10-1.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-10-2.webp" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-10-3.webp" />
                      </span>
                    </a>
                  </li> */}
                  {/* <li className="port">
                    <a href="assets/images/portfolio/ecommerce/inner/11.png" data-fancybox="port"
                      className="column website-pack-preview fancybox">
                      <span className="card">
                        <img className="img_porr_front" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-11-1.png" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-11-2.png" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-11-3.png" />
                      </span>
                    </a>
                  </li>
                  <li className="port">
                    <a href="assets/images/portfolio/ecommerce/inner/12.png" data-fancybox="port"
                      className="column website-pack-preview fancybox">
                      <span className="card">
                        <img className="img_porr_front" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-12-1.png" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-12-2.png" />
                      </span>
                      <span className="card">
                        <img className="img_porr" width="220" height="330"
                          src="assets/images/portfolio/ecommerce/thumbnail/pack-12-3.png" />
                      </span>
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="tab-pane fade" id="pills-ecommerce" role="tabpanel" aria-labelledby="pills-ecommerce-tab"
                tabindex="0">
                <ul className="tabs tab-e-com  mportt">
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/UIUX/uiux11.webp">
                      <figure>
                        <img src="assets/images/UIUX/uiux11.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/UIUX/uiux2.webp">
                      <figure>
                        <img src="assets/images/UIUX/uiux2.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/UIUX/uiux3.webp">
                      <figure>
                        <img src="assets/images/UIUX/uiux3.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/UIUX/uiux4.webp">
                      <figure>
                        <img src="assets/images/UIUX/uiux4.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/UIUX/uiux5.webp">
                      <figure>
                        <img src="assets/images/UIUX/uiux5.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/UIUX/uiux6.webp">
                      <figure>
                        <img src="assets/images/UIUX/uiux6.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/UIUX/uiux7.webp">
                      <figure>
                        <img src="assets/images/UIUX/uiux7.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/UIUX/uiux8.webp">
                      <figure>
                        <img src="assets/images/UIUX/uiux8.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/UIUX/uiux10.webp">
                      <figure>
                        <img src="assets/images/UIUX/uiux10.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="tab-pane fade" id="pills-smm" role="tabpanel" aria-labelledby="pills-smm-tab"
                tabindex="0">
                <ul className="tabs tab-e-com  mportt">
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/e-commerce/portfolio-1.jpg">
                      <figure>
                        <img src="assets/images/e-commerce/1.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/e-commerce/2.jpg">
                      <figure>
                        <img src="assets/images/e-commerce/2.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/e-commerce/3.jpg">
                      <figure>
                        <img src="assets/images/e-commerce/3.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/e-commerce/portfolio-4.jpg">
                      <figure>
                        <img src="assets/images/e-commerce/4.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/e-commerce/portfolio-5.jpg">
                      <figure>
                        <img src="assets/images/e-commerce/5.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/e-commerce/portfolio-6.jpg">
                      <figure>
                        <img src="assets/images/e-commerce/6.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/e-commerce/portfolio-7.jpg">
                      <figure>
                        <img src="assets/images/e-commerce/7.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/e-commerce/portfolio-8.jpg">
                      <figure>
                        <img src="assets/images/e-commerce/8.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/e-commerce/portfolio-9.jpg">
                      <figure>
                        <img src="assets/images/e-commerce/9.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/e-commerce/10.jpg">
                      <figure>
                        <img src="assets/images/e-commerce/10.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>

                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/e-commerce/portfolio-11.jpg">
                      <figure>
                        <img src="assets/images/e-commerce/11.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>

                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/e-commerce/portfolio-12.jpg">
                      <figure>
                        <img src="assets/images/e-commerce/12.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                </ul>
              </div> */}
              <div className="tab-pane fade" id="pills-motion-graphic" role="tabpanel" aria-labelledby="pills-motion-graphic-tab"
                tabindex="0">
                <ul className="tabs tab-e-com  mportt">
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/social_media/social_media1.webp">
                      <figure>
                        <img src="assets/images/social_media/social_media1.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/social_media/social_media2.webp">
                      <figure>
                        <img src="assets/images/social_media/social_media2.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/social_media/social_media3.webp">
                      <figure>
                        <img src="assets/images/social_media/social_media3.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/social_media/social_media4.webp">
                      <figure>
                        <img src="assets/images/social_media/social_media4.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/social_media/social_media5.webp">
                      <figure>
                        <img src="assets/images/social_media/social_media5.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/social_media/social_media6.webp">
                      <figure>
                        <img src="assets/images/social_media/social_media6.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/social_media/social_media7.jpg">
                      <figure>
                        <img src="assets/images/social_media/social_media7.jpg" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/social_media/social_media8.webp">
                      <figure>
                        <img src="assets/images/social_media/social_media8.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                  <li className="port four-port">
                    <a className="fancybox" data-fancybox="port" href="assets/images/social_media/social_media9.webp">
                      <figure>
                        <img src="assets/images/social_media/social_media2.webp" alt="" />
                        <div className="overlay">
                          <div className="text"><i className="fa fa-search-plus" aria-hidden="true"></i></div>
                        </div>
                      </figure>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        </>
    );
}