import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import MobileApp from '../assets/img/mobile-app.webp';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

export default function OurBlogs() {
    useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return (
        <>
    <Helmet>
        <title>IT Power Systems LLC - Our Blogs</title>
        <meta name="description" content="Stay updated with the latest industry insights and trends. Read our expert blogs on digital marketing, mobile app development, and tech innovations - Our Blogs" />
    </Helmet>
            <section className="inner-banner blog-banner">
                <div className="banner-content">
                    <div className="container">
                        <div className="row banner-b justify-content-center">
                            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Our Blogs</span></h2>
                                <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Our Blogs</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- blogs sec start --> */}
            <section className="blog-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="blogs">
                                <div className="row gy-4">
                                    <div className="col-12 col-xl-4 col-lg-6 col-md-6 panel">
                                        <div className="blog h-100" data-aos="fade-right" data-aos-duration="2000">
                                            <img src="assets/images/blogs/How-SEO-Can-Transform-Your-Business.jpg" alt="" />
                                            <p className="detail">
                                                <span><i className="fas fa-user"></i> admin </span>
                                                <span> <i className="fa-solid fa-calendar-days"></i> 26-06-2024</span>
                                            </p>
                                            <h4>How SEO Can Transform Your Business: Case Studies and Success Stories</h4>
                                            <p> Search Engine Optimization (SEO) can significantly impact your business by improving your online visibility and driving organic traffic to your website....</p>
                                            <ul className="btn-b">
                                                <li>
                                                    <Link to="/blog-detail" className="theme-btn theme-btn-gre">
                                                        Read More
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-6 col-md-6 panel">
                                        <div className="blog h-100" data-aos="fade-right" data-aos-duration="2000">
                                            <img src="assets/images/blogs/Top-5-Digital-Marketing-Mistakes.jpg" alt="" />
                                            <p className="detail">
                                                <span><i className="fas fa-user"></i> admin </span>
                                                <span> <i className="fa-solid fa-calendar-days"></i> 26-06-2024</span>
                                            </p>
                                            <h4>Top 5 Digital Marketing Trends to Watch in 2024 The digital marketing landscape</h4>
                                            <p>Top 5 Digital Marketing Trends to Watch in 2024 The digital marketing landscape is constantly evolving, and staying ahead of the curve is essential....</p>
                                            <ul className="btn-b">
                                                <li>
                                                    <Link to="/digital-marketing-BlogDetail" className="theme-btn theme-btn-gre">
                                                        Read More
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-6 col-md-6 panel">
                                        <div className="blog h-100" data-aos="fade-right" data-aos-duration="2000">
                                            <img src="assets/images/blogs/The-Importance-of-UIUX-Design.jpg" alt="" />
                                            <p className="detail">
                                                <span><i className="fas fa-user"></i> admin </span>
                                                <span> <i className="fa-solid fa-calendar-days"></i> 26-06-2024</span>
                                            </p>
                                            <h4>The Importance of UI/UX Design in Web and Mobile Development</h4>
                                            <p>UI/UX design plays a crucial role in the success of web and mobile applications. It directly impacts user satisfaction and engagement....</p>
                                            <ul className="btn-b">
                                                <li>
                                                    <Link to="/uiux-blogdetail" className="theme-btn theme-btn-gre">
                                                        Read More
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-6 col-md-6 panel">
                                        <div className="blog h-100" data-aos="fade-right" data-aos-duration="2000">
                                            <img src="assets/images/blogs/The-Benefits-of-Mobile-App.jpg" alt="" />
                                            <p className="detail">
                                                <span><i className="fas fa-user"></i> admin </span>
                                                <span> <i className="fa-solid fa-calendar-days"></i> 03-07-2024</span>
                                            </p>
                                            <h4>The Benefits of Mobile App Development for Startups</h4>
                                            <p>In today's digital age, having a mobile app can be a game-changer for startups. It provides a direct channel to engage with.....</p>
                                            <ul className="btn-b">
                                                <li>
                                                    <Link to="/mobile-app-blogdetail" className="theme-btn theme-btn-gre">
                                                        Read More
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-6 col-md-6 panel">
                                        <div className="blog h-100" data-aos="fade-right" data-aos-duration="2000">
                                            <img src="assets/images/blogs/Maximizing-ROI-with-Social-Media-Marketing.jpg" alt="" />
                                            <p className="detail">
                                                <span><i className="fas fa-user"></i> admin </span>
                                                <span> <i className="fa-solid fa-calendar-days"></i> 03-07-2024</span>
                                            </p>
                                            <h4>Maximizing ROI with Social Media Marketing: Strategies for 2024</h4>
                                            <p>Social media marketing is a powerful tool for maximizing return on investment (ROI). With the right....</p>
                                            <ul className="btn-b">
                                                <li>
                                                    <Link to="/social-media-blogdetail" className="theme-btn theme-btn-gre">
                                                        Read More
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-6 col-md-6 panel">
                                        <div className="blog h-100" data-aos="fade-right" data-aos-duration="2000">
                                            <img src="assets/images/blogs/Top-10-Graphic-Design.jpg" alt="" />
                                            <p className="detail">
                                                <span><i className="fas fa-user"></i> admin </span>
                                                <span> <i className="fa-solid fa-calendar-days"></i> 03-07-2024</span>
                                            </p>
                                            <h4>Top 10 Graphic Design Trends to Watch in 2024</h4>
                                            <p>The world of graphic design is ever-evolving, with new trends emerging each year. Staying updated with these trends....</p>
                                            <ul className="btn-b">
                                                <li>
                                                    <Link to="/graphic-design-blogdetail" className="theme-btn theme-btn-gre">
                                                        Read More
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-6 col-md-6 panel">
                                        <div className="blog h-100" data-aos="fade-right" data-aos-duration="2000">
                                            <img src={MobileApp} alt="Mobile App" />
                                            <p className="detail">
                                                <span><i className="fas fa-user"></i> admin </span>
                                                <span> <i className="fa-solid fa-calendar-days"></i> 07-08-2024</span>
                                            </p>
                                            <h4>Top 10 Benefits of Custom Mobile App Development for Businesses in 2024</h4>
                                            <p>As businesses increasingly turn to digital solutions to stay competitive, custom mobile app development has emerged as a key strategy for growth and customer engagement.....</p>
                                            <ul className="btn-b">
                                                <li>
                                                    <Link to="/app-mobile-blogdetail" className="theme-btn theme-btn-gre">
                                                        Read More
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-6 col-md-6 panel">
                                        <div className="blog h-100" data-aos="fade-right" data-aos-duration="2000">
                                            <img src="assets/images/blogs/Grow Your Online Store with Scalable Ecommerce Web Solutions.webp" alt="Mobile App" />
                                            <p className="detail">
                                                <span><i className="fas fa-user"></i> admin </span>
                                                <span> <i className="fa-solid fa-calendar-days"></i> 07-08-2024</span>
                                            </p>
                                            <h4>Grow Your Online Store with Scalable Ecommerce Web Solutions</h4>
                                            <p>In today's fast-paced digital world, having a robust online store is crucial for business success. Ecommerce web solutions are designed to provide businesses with the tools.....</p>
                                            <ul className="btn-b">
                                                <li>
                                                    <Link to="/ecommerce-web-blogdetail" className="theme-btn theme-btn-gre">
                                                        Read More
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-6 col-md-6 panel">
                                        <div className="blog h-100" data-aos="fade-right" data-aos-duration="2000">
                                        <img src="assets/images/blogs/5 Signs Your Business Needs a Mobile App in 2024.webp" alt="" />
                                        <p className="detail">
                                                <span><i className="fas fa-user"></i> admin </span>
                                                <span> <i className="fa-solid fa-calendar-days"></i> 07-08-2024</span>
                                            </p>
                                            <h4>5 Signs Your Business Needs a Mobile App in 2024</h4>
                                            <p>In the fast-paced digital world of 2024, businesses are increasingly looking for ways to stay competitive and meet the evolving needs of their customers. One of the most effective strategies is to develop......</p>
                                            <ul className="btn-b">
                                                <li>
                                                    <Link to="/mobile-app-blog-details" className="theme-btn theme-btn-gre">
                                                        Read More
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-6 col-md-6 panel">
                                        <div className="blog h-100" data-aos="fade-right" data-aos-duration="2000">
                                            <img src="assets/images/blogs/The Importance of User Experience (UX) in Mobile App Developme.webp" alt="Mobile App" />
                                            <p className="detail">
                                                <span><i className="fas fa-user"></i> admin </span>
                                                <span> <i className="fa-solid fa-calendar-days"></i> 09-08-2024</span>
                                            </p>
                                            <h4>The Importance of User Experience (UX) in Mobile App Developme</h4>
                                            <p>In the highly competitive landscape of mobile apps, user experience (UX) design plays a pivotal role in determining the success of an app. At IT Power Systems LLC......</p>
                                            <ul className="btn-b">
                                                <li>
                                                    <Link to="/uiux-mobile-app-blog-details" className="theme-btn theme-btn-gre">
                                                        Read More
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-6 col-md-6 panel">
                                        <div className="blog h-100" data-aos="fade-right" data-aos-duration="2000">
                                            <img src="assets/images/blogs/Why Content Marketing is Crucial for Your Digital Strategy.webp" alt="Mobile App" />
                                            <p className="detail">
                                                <span><i className="fas fa-user"></i> admin </span>
                                                <span> <i className="fa-solid fa-calendar-days"></i> 09-08-2024</span>
                                            </p>
                                            <h4>Why Content Marketing is Crucial for Your Digital Strategy</h4>
                                            <p>In the digital age, content marketing is more than just a trend—it's a fundamental element of any successful digital strategy. As businesses strive to connect with......</p>
                                            <ul className="btn-b">
                                                <li>
                                                    <Link to="/Content-marketing-blog-details" className="theme-btn theme-btn-gre">
                                                        Read More
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-6 col-md-6 panel">
                                        <div className="blog h-100" data-aos="fade-right" data-aos-duration="2000">
                                            <img src="assets/images/blogs/How Social Media Marketing Can Boost Your Brand Awareness.webp" alt="Mobile App" />
                                            <p className="detail">
                                                <span><i className="fas fa-user"></i> admin </span>
                                                <span> <i className="fa-solid fa-calendar-days"></i> 09-08-2024</span>
                                            </p>
                                            <h4>How Social Media Marketing Can Boost Your Brand Awareness</h4>
                                            <p>In today’s digital landscape, social media marketing has become an essential tool for building brand awareness. With billions of users across platforms like Facebook......</p>
                                            <ul className="btn-b">
                                                <li>
                                                    <Link to="/social-media-marketing-Blog-Detail" className="theme-btn theme-btn-gre">
                                                        Read More
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}