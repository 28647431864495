import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import SocialMediaMarketing from '../utils/SocialMediaMarketing';
import service1 from '../assets/img/Services/graphicdesign.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Card from './Card';
import { Helmet } from 'react-helmet';

export default function SocialMediaMarketingService(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return(
      <>
      <Helmet>
        <title>IT Power Systems LLC - Social Media Marketing</title>
        <meta name="description" content="Boost your brand’s visibility with strategic social media marketing. Our tailored campaigns increase engagement and drive targeted traffic across platforms - Social Media Marketing" />
      </Helmet>
      <section className="inner-banner social-media">
        <div className="banner-content">
          <div className="container">
            <div className="row banner-b justify-content-center">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Social Media Marketing</span></h2>
                <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Social Media Marketing</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec inner-about inner-serv">
        <div className="container">
          <div className="row justify-content-center gy-4">
            <div className="col-xl- col-lg-8 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
              <div>
                <img className='img-fluid' src='assets/images/social-media-marketing-image.jpg' />
              </div>
              <div className="content"> 
                <h2 className="theme-h2">Social Media Marketing Services</h2>
                <p>At IT Power Systems LLC, our social media marketing services are designed to elevate your brand's presence across major social platforms. We craft tailored social media strategies that engage your audience, foster community, and drive conversions. Our comprehensive approach includes content creation, campaign management, paid social media advertising, and in-depth analytics and reporting to ensure your brand achieves its goals.</p>
                <p>We understand the nuances of each platform and use this expertise to maximize your brand’s impact. By building authentic connections with your audience, we help enhance brand loyalty and drive measurable growth.</p>
                <h2 className="theme-h2">Services We Provide:</h2>
                <ul>
                  <li>Social Media Strategy Development</li>
                  <li>Content Creation and Curation</li>
                  <li>Paid Social Media Advertising</li>
                  <li>Social Media Account Management</li>
                  <li>Analytics and Reportin</li>
                </ul>
                <h2 className="theme-h2">Why Choose Us:</h2>
                <p>With a proven track record of delivering successful social media campaigns, our team stays ahead of the latest trends to keep your brand relevant and competitive. We create customized strategies tailored to your business needs, ensuring that you achieve maximum ROI and lasting engagement.</p>
                <p>Ready to amplify your social media presence? Contact IT Power Systems LLC today to grow your brand and effectively engage your audience</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
              <div className="ser-box">
                <img src="assets/images/services-details-icon-01.png" alt="logo design service" className="img-fluid"/>
                <h2 className="theme-h2">Don't hesitate to contact us</h2>
                <p>At our IT solution company, we are committed to exceptional</p>
                <Link className="theme-btn" to="/contact-us">Get In Touch </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="pricing py-5">
        <div className="container">
          <div className="row">
            <div className='text-center pb-5 text-white'>
              <h1 className='pb-2' style={{ fontWeight: 'bold' }}>Choose your right plan!</h1>
              <p className='text-white'>Select from best plan, ensuring a perfect match, Need more or less?</p>
              <p className='text-white pb-2'>Customize Your subscription for a seamless fit!</p>
            </div>
            {
              SocialMediaMarketing?.map((obj) => { return <Card data={obj} /> })   // store data in myPrices map to card 
            }
          </div>
        </div>
      </section>
      
      <section className="ctabanner" >
          <div className="container">
              <div className="box-cta" data-aos="fade-up" data-aos-duration="3000">
                <div className="row justify-content-center align-items-center">
                  <div className="col-12 col-sm-12 col-md-11 col-lg-12 col-xl-11 col-xxl-11">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="ctatext">
                          <p>IT Power Systems LLC,</p>
                          <h3>Your business can reach its full potential.</h3>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="calllsit">
                              <div className=" marginleft">
                              <Link className="theme-btn" to="/contact-us">Contact Us</Link>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </section>
      </>
    );
}