import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import service1 from '../assets/img/Services/graphicdesign.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function BlogDetail(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return(
      <>
      <section className="inner-banner">
        <div className="banner-content">
          <div className="container">
            <div className="row banner-b justify-content-center">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Top 10 Graphic Design Trends to Watch in 2024</span></h2>
                <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Top 10 Graphic Design Trends to Watch in 2024</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    <section className="about-sec inner-about inner-serv blog-detail">
        <div className="container">
          <div className="row justify-content-center gy-4">
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
              <div className="content">
                <h2 className="theme-h2">Top 10 Graphic Design Trends to Watch in 2024</h2>
                <p> The world of graphic design is ever-evolving, with new trends emerging each year. Staying updated with these trends is crucial for businesses looking to keep their visual content fresh and engaging. Here are the top 10 graphic design trends to watch in 2024.
                </p>
                <div>
                  <img className='img-fluid' src='assets/images/blogs/Top-10-Graphic-Design.jpg' />
                </div>
                  <p className="detail">
                      <span><i className="fas fa-user"></i> admin </span>
                      <span> <i className="fa-solid fa-calendar-days"></i> 06-26-2024</span> 
                  </p>
                <h2 className="theme-h2">Top 10 Graphic Design Trends to Watch in 2024</h2>
                  <p><span className='spanbold'>Minimalist Designs: </span> Clean, simple, and clutter-free designs are making a comeback, emphasizing the importance of whitespace and straightforward messaging.</p>
                  <p><span className='spanbold'>Bold Typography: </span>Strong, eye-catching fonts are being used to make statements and grab attention. Experiment with size, weight, and color.</p>
                  <p><span className='spanbold'>3D Elements: </span>Incorporating 3D elements into designs adds depth and realism, making visuals more engaging and dynamic.</p>
                  <p><span className='spanbold'>Muted Color Palettes: </span> Soft, muted colors are replacing bright, bold hues, offering a more sophisticated and calming aesthetic.</p>
                  <p><span className='spanbold'>Abstract Illustrations: </span>Unique, abstract illustrations are being used to convey complex ideas creatively and distinctively.</p>
                  <p><span className='spanbold'>Geometric Patterns: </span>Geometric shapes and patterns are trending, providing a modern and structured look.</p>
                  <p><span className='spanbold'>Asymmetry and Broken Grid Layouts: </span>Breaking away from traditional grid layouts creates more dynamic and interesting designs.</p>
                  <p><span className='spanbold'>Motion Graphics: </span>Animated graphics and videos are increasingly popular for their ability to tell a story and capture attention.</p>
                  <p><span className='spanbold'>Retro and Vintage Styles: </span>Nostalgic designs are making a comeback, with a modern twist on vintage aesthetics.</p>
                  <p><span className='spanbold'>Inclusive Design: </span>Designing with diversity and accessibility in mind is becoming essential, ensuring that visuals resonate with a broader audience.</p>
                  <p><span className='spanbold'>Conclusion: </span>Staying ahead of these graphic design trends can help your brand remain relevant and appealing. At IT Power Systems LLC, our talented designers are adept at integrating the latest trends into your visual content, ensuring your brand stands out in a crowded marketplace.</p>
                 </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
              <div className="recent-blogs">
                <h2>Recent Posts</h2>
                <Link to="/blog-detail" className="recent-blog">
                    <img src="assets/images/blogs/How-SEO-Can-Transform-Your-Business.jpg" alt="" />
                    <div>
                        <h3>How SEO Can Transform Your Business: Case Studies and Success Stories</h3>
                        {/* <h4>September 26, 2023</h4> */}
                        <p>Search Engine Optimization (SEO) can significantly impact your business by improving....</p>
                    </div>
                </Link>
                <Link to="/uiux-blogdetail" className="recent-blog">
                    <img src="assets/images/blogs/The-Importance-of-UIUX-Design.jpg" alt="" />
                    <div>
                        <h3>The Importance Of UI/UX Design In Web And Mobile Development</h3>
                        {/* <h4>September 26, 2023</h4> */}
                        <p>UI/UX design plays a crucial role in the success of web and mobile applications. It directly....</p>
                    </div>
                </Link>
                <Link to="/digital-marketing-BlogDetail" className="recent-blog">
                    <img src="assets/images/blogs/Top-5-Digital-Marketing-Mistakes.jpg" alt="" />
                    <div>
                        <h3>Top 5 Digital Marketing Mistakes and How to Avoid Them</h3>
                        {/* <h4>September 26, 2023</h4> */}
                        <p>Digital marketing is a dynamic field that requires continuous learning and adaptation....</p>
                    </div>
                </Link>
                <Link to="/mobile-app-blogdetail" className="recent-blog">
                    <img src="assets/images/blogs/The-Benefits-of-Mobile-App.jpg" alt="" />
                    <div>
                        <h3>The Benefits of Mobile App Development for Startups</h3>
                        {/* <h4>September 26, 2023</h4> */}
                        <p>In today's digital age, having a mobile app can be a game-changer for startups. It provides a direct channel to engage.....</p>
                    </div>
                </Link>
                <Link to="/social-media-blogdetail" className="recent-blog">
                    <img src="assets/images/blogs/Maximizing-ROI-with-Social-Media-Marketing.jpg" alt="" />
                    <div>
                        <h3>Maximizing ROI with Social Media Marketing: Strategies for 2024</h3>
                        {/* <h4>September 26, 2023</h4> */}
                        <p>Social media marketing is a powerful tool for maximizing return on investment (ROI).....</p>
                    </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
    );
}