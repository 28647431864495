import React from "react";
import service1 from '../assets/img/Services/software-development-pic.png';


export default function CaseStudies(){
    return(
      <section id="ourproduct" className="departments pt-5">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-md-12">
            {/* <!-- Heading --> */}
            <div className="section-title pt-5">
              <h2>OUR CASE STUDIES</h2>
              <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
            </div>
          </div>
        </div>
        <div className="row">
         
          <div className="col-lg-6 mb-4" data-aos="fade-left" data-aos-delay="100">
                  <div className="service-content pt-4">
                    <h3>Why Choose Our Graphic Designing Service</h3>
                    <p>Our graphic designing service is aimed at providing visually appealing and effective designs that resonate with your brand's identity. Here's why you should choose usdesigning service is aimed at providing visually appealing and effective designs that resonate with your brand's identity. Here's why you should choose us:</p>
                    {/* <ul>
                      <li>Experienced and creative designers</li>
                      <li>Customized solutions tailored to your needs</li>
                      <li>Quick turnaround time</li>
                      <li>Excellent customer support</li>
                      <li>Excellent customer support</li>
                      <li>Excellent customer support</li>
                    </ul> */}
                    <p>Contact us today to discuss Contact us today to discuss  Contact us today to discuss your graphic design requirements and take your brand to the next level Contact us today to discuss Contact us today to discuss  Contact us today to discuss your graphic design requirements and take your brand to the next level.</p>
                  </div>
          </div>
    
          <div className="col-md-6">
          <img src={service1} className="img-fluid" alt="Case Study Image"/>
          </div>
        </div>
        <div className="row">
        <div className="col-md-6">
          <img src={service1} className="img-fluid" alt="Case Study Image"/>
          </div>
          <div className="col-lg-6 mb-4" data-aos="fade-left" data-aos-delay="100">
                  <div className="service-content pt-4">
                    <h3>Why Choose Our Graphic Designing Service</h3>
                    <p>Our graphic designing service is aimed at providing visually appealing and effective designs that resonate with your brand's identity. Here's why you should choose usdesigning service is aimed at providing visually appealing and effective designs that resonate with your brand's identity. Here's why you should choose us:</p>
                    {/* <ul>
                      <li>Experienced and creative designers</li>
                      <li>Customized solutions tailored to your needs</li>
                      <li>Quick turnaround time</li>
                      <li>Excellent customer support</li>
                      <li>Excellent customer support</li>
                      <li>Excellent customer support</li>
                    </ul> */}
                    <p>Contact us today to discuss Contact us today to discuss  Contact us today to discuss your graphic design requirements and take your brand to the next level Contact us today to discuss Contact us today to discuss  Contact us today to discuss your graphic design requirements and take your brand to the next level.</p>
                  </div>
          </div>  
        </div>
        <div className="row">
         
         <div className="col-lg-6 mb-4" data-aos="fade-left" data-aos-delay="100">
                 <div className="service-content pt-4">
                   <h3>Why Choose Our Graphic Designing Service</h3>
                   <p>Our graphic designing service is aimed at providing visually appealing and effective designs that resonate with your brand's identity. Here's why you should choose usdesigning service is aimed at providing visually appealing and effective designs that resonate with your brand's identity. Here's why you should choose us:</p>
                   {/* <ul>
                     <li>Experienced and creative designers</li>
                     <li>Customized solutions tailored to your needs</li>
                     <li>Quick turnaround time</li>
                     <li>Excellent customer support</li>
                     <li>Excellent customer support</li>
                     <li>Excellent customer support</li>
                   </ul> */}
                   <p>Contact us today to discuss Contact us today to discuss  Contact us today to discuss your graphic design requirements and take your brand to the next level Contact us today to discuss Contact us today to discuss  Contact us today to discuss your graphic design requirements and take your brand to the next level.</p>
                 </div>
         </div>
   
         <div className="col-md-6">
         <img src={service1} className="img-fluid" alt="Case Study Image"/>
         </div>
       </div>
      </div>
    </section>
    );
}