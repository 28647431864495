import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import emailjs from 'emailjs-com';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

export default function ContactUs() {
    useEffect(() => {
      // Initialize AOS
      AOS.init();
  }, []);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hh3x3ut', 'template_6rnh9dn', e.target, '_XtATLzyRJMsHTqxk')
      .then((result) => {
          // console.log(result.text);
          // alert('Message sent successfully!');
          navigate('/thank-you');
      }, (error) => {
          // console.log(error.text);
          // alert('An error occurred, please try again later.');
      });
    
    e.target.reset();
  };

  return (
    <>
    <Helmet>
        <title>IT Power Systems LLC - Contact Us</title>
        <meta name="description" content="Get in touch with IT Power Systems LLC for custom technology solutions. We're here to help you transform your business with innovative IT services - Contact Us" />
    </Helmet>
    <section className="inner-banner contact-banner">
      <div className="banner-content">
        <div className="container">
          <div className="row banner-b justify-content-center">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
              <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Contact Us</span></h2>
              <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Contact Us</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="contact-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 pt-5">
              <div className="content" data-aos="fade-right" data-aos-duration="3000">
                <h2 className="theme-h2 pt-5">
                  We would love to <span>serve you</span>
                </h2>
                <p>At IT Power Systems LLC , we are dedicated to providing top-notch digital solutions that elevate your business to new heights. Whether you have a question about our services, need a custom quote, or want to discuss your next big project, we're here to help. Reach out to us today and let's start a conversation about how we can drive your business forward. We look forward to connecting with you!</p>
                <ul>
                  <li><a href="tel:+1 (937) 966-0774"><span><i className="fa-regular fa-phone-volume"></i></span>
                  +1 828-738-2210</a></li>
                  <li><a href="tel:+1 (937) 966-0774"><span><i class="fa-brands fa-whatsapp"></i></span>
                  +1 571-622-0760</a></li>
                  <li><a href="mailto:info@itpowersystems.com"><span><i className="fa-regular fa-envelope"></i></span>
                    info@itpowersystems.com</a></li>
                  {/* <li><a href="/"><span><i className="fa-regular fa-location-dot"></i></span>
                  USA 13th Street. 47 W 13th St, New York, NY 10011</a>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="form-box" data-aos="fade-left" data-aos-duration="3000">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                      <div className="col-lg-12">
                          <div className="txt-box">
                          <h3>Say Hi Get a Quote</h3>
                          <label for="">name*</label>
                              <input placeholder="name" type="text" value={formData.name} onChange={handleChange} name="name" required />
                          </div>
                          <div className="txt-box">
                              <label for="">email*</label>
                              <input type="text" name="email" value={formData.email} onChange={handleChange} placeholder="email" required />
                          </div>
                          <div className="txt-box">
                              <label for="">phone*</label>
                              <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="phone" required />
                          </div>
                          <div className="txt-box">
                              <label for="">message*</label>
                              <textarea name="message" placeholder="name" value={formData.message} onChange={handleChange} id="" cols="30" rows="6" required></textarea>
                          </div>
                          <div className="btn-sub">
                              <button type="submit" className="theme-btn">send message</button>
                          </div>
                      </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      </>
  );
}
