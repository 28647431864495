let SocialMediaMarketing = [
    {
        plan: "Basic",
        price: "$500/month",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Basic analysis of your social media presence.",
                isEnabled: true
            },
            {
                name: "10 creative posts per month including videos.",
                isEnabled: true
            },
            {
                name: "Monthly content plan tailored to your brand.",
                isEnabled: true
            },
            {
                name: "Basic reports on social media performance.",
                isEnabled: true
            },
            {
                name: "Scheduled posts for consistent engagement.",
                isEnabled: true
            },
            {
                name: "Basic management of social media ad campaigns.",
                isEnabled: false
            },
            {
                name: "Custom graphics designed for your posts.",
                isEnabled: false
            },
            {
                name: "Dedicated social media manager.",
                isEnabled: false
            },
            {
                name: "No Ad Budget included.",
                isEnabled: false
            },
            {
                name: "Engaging with followers and managing interactions.",
                isEnabled: false
            },
            {
                name: "Collaborate with influencers to boost your brand.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Standard",
        price: "$1,000/month",
        recommended: true,
        button: "Get Started",
        features: [
            {
                name: "In-depth analysis of your social media presence.",
                isEnabled: true
            },
            {
                name: "15 creative posts per month including videos.",
                isEnabled: true
            },
            {
                name: "Monthly content plan tailored to your brand.",
                isEnabled: true
            },
            {
                name: "Detailed reports on social media performance.",
                isEnabled: true
            },
            {
                name: "Scheduled posts for consistent engagement.",
                isEnabled: true
            },
            {
                name: "Basic management of social media ad campaigns.",
                isEnabled: true
            },
            {
                name: "Custom graphics designed for your posts.",
                isEnabled: true
            },
            {
                name: "Dedicated social media manager.",
                isEnabled: true
            },
            {
                name: "50$ Ad Budget included.",
                isEnabled: true
            },
            {
                name: "Engaging with followers and managing interactions.",
                isEnabled: false
            },
            {
                name: "Collaborate with influencers to boost your brand.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Premium",
        price: "$2,000/month",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "In-depth analysis of your social media presence.",
                isEnabled: true
            },
            {
                name: "20 creative posts per month including videos.",
                isEnabled: true
            },
            {
                name: "Monthly content plan tailored to your brand.",
                isEnabled: true
            },
            {
                name: "Detailed reports on social media performance.",
                isEnabled: true
            },
            {
                name: "Scheduled posts for consistent engagement.",
                isEnabled: true
            },
            {
                name: "Full management of social media ad campaigns.",
                isEnabled: true
            },
            {
                name: "Custom graphics designed for your posts.",
                isEnabled: true
            },
            {
                name: "Dedicated social media manager.",
                isEnabled: true
            },
            {
                name: "100$ Ad Budget included.",
                isEnabled: true
            },
            {
                name: "Engaging with followers and managing interactions.",
                isEnabled: true
            },
            {
                name: "Collaborate with influencers to boost your brand.",
                isEnabled: true
            },
        ]
    }
]

export default SocialMediaMarketing;