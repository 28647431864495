import React from 'react';
function Card(props) {
    return <>
        <div className="col-lg-4 pb-5">
            <div className="card mb-5 mb-lg-0">
                <div className="card-body">
                    <div className='position-relative'>
                        <div className={props.data?.recommended && 'position-absolute text-white text-center py-2 px-5 mb-2'}
                            style={{ top: '-35px', left: '90px', background: '#E76A34', borderRadius: '25px' }}
                        >
                            {
                                props.data?.recommended && "Recommended"
                            }
                        </div>
                    </div>
                    <h5 class="card-title text-muted text-uppercase text-center pt-4">{props.data.plan}</h5>             {/* plan is title of card */}
                    <h6 class="card-price text-center">{props.data.price}</h6>               {/*this line  is price of card */}
                    <hr />
                    <ul className="fa-ul">
                        {
                            props.data.features.map((feature) => {
                                return (
                                    <>
                                        <li className={feature.isEnabled ? "" : "text-muted"}>               {/* this line is to disable the text (text-muted) */}
                                            <span className="fa-li">
                                                <i class={`fa ${feature.isEnabled ? 'fa-check' : 'fa-times'}`}></i>        {/* this line to font awesome icon ✔ and ✖ icon */}
                                            </span>{feature.name}             {/* this line is to other content in card  */}
                                        </li>
                                    </>
                                )
                            })
                        }
                    </ul>
                    <hr />
                    <button className="btn btn-primary">{props.data.button}</button>
                </div>
            </div>
        </div>
    </>
}
export default Card;