import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import myPrices from '../utils/designPrice';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Card from './Card';
import '../App.css';
import { Helmet } from 'react-helmet';

export default function GraphicDesignService() {
  useEffect(() => {
    // Initialize AOS
    AOS.init();
  }, []);

  return (
    <>
      <Helmet>
        <title>IT Power Systems LLC - Graphic Design</title>
        <meta name="description" content="Unlock the power of visual storytelling with our expert graphic design services. We create stunning logos, branding materials, and digital designs that elevate your brand - Graphic Design" />
      </Helmet>
      <section className="inner-banner graphic">
        <div className="banner-content">
          <div className="container">
            <div className="row banner-b justify-content-center">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Graphic Design</span></h2>
                <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Graphic Design</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec inner-about inner-serv">
        <div className="container">
          <div className="row justify-content-center gy-4">
            <div className="col-xl- col-lg-8 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
              <div>
                <img className='img-fluid' src='assets/images/graphic-design-image.jpg' />
              </div>
              <div className="content">
                <h2 className="theme-h2">Graphic Design Services</h2>
                <p>At IT Power Systems LLC, our graphic design services are crafted to bring your brand’s vision to life with creativity and precision. We specialize in creating custom logo designs, branding materials, and marketing collateral that resonate with your audience. Our goal is to enhance your brand’s identity and communication through visually compelling designs.</p>
                <p>Our experienced designers collaborate closely with you to understand your brand’s personality and objectives. Whether you need a complete brand overhaul or specific design projects, we deliver creative solutions that make a lasting impact.</p>
                <h2 className="theme-h2">Services We Provide:</h2>
                <ul>
                  <li>Custom Logo Design</li>
                  <li>Brochure and Flyer Design</li>
                  <li>Social Media Graphics</li>
                  <li>Branding and Identity Design</li>
                  <li>Custom Illustrations</li>
                </ul>
                <h2 className="theme-h2">Why Choose Us: </h2>
                <p>Our designers combine artistic creativity with strategic insight to produce designs that elevate your brand. With a deep understanding of branding and marketing, we ensure that every design not only looks stunning but also achieves your business goals. Trust IT Power Systems LLC to create graphic designs that set your brand apart.</p>
                <p>Ready to elevate your brand’s visual impact? Contact IT Power Systems LLC today and let our graphic design services bring your vision to life.</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
              <div className="ser-box">
                <img src="assets/images/services-details-icon-01.png" alt="logo design service" className="img-fluid" />
                <h2 className="theme-h2">Don't hesitate to contact us</h2>
                <p>At our IT solution company, we are committed to exceptional</p>
                <Link className="theme-btn" to="/contact-us">Get In Touch </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing py-5">
        <div className="container">
          <div className="row">
            <div className='text-center pb-5 text-white'>
              <h1 className='pb-2' style={{ fontWeight: 'bold' }}>Choose your right plan!</h1>
              <p className='text-white'>Select from best plan, ensuring a perfect match, Need more or less?</p>
              <p className='text-white pb-2'>Customize Your subscription for a seamless fit!</p>
            </div>
            {
              myPrices?.map((obj) => { return <Card data={obj} /> })   // store data in myPrices map to card 
            }
          </div>
        </div>
      </section>
      <section className="ctabanner" >
        <div className="container">
          <div className="box-cta" data-aos="fade-up" data-aos-duration="3000">
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-sm-12 col-md-11 col-lg-12 col-xl-11 col-xxl-11">
                <div className="row align-items-center">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="ctatext">
                      <p>IT Power Systems LLC,</p>
                      <h3>Your business can reach its full potential.</h3>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="calllsit">
                      <div className=" marginleft">
                        <Link className="theme-btn" to="/contact-us">Contact Us </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}