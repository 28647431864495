import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

export default function AboutUs(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return (       
      <>
      <Helmet>
        <title>IT Power Systems LLC - About Us</title>
        <meta name="description" content="Learn more about IT Power Systems LLC, a leading provider of custom technology solutions. Explore our mission, values, and expertise in driving digital transformation - About Us" />
      </Helmet>
      <section className="inner-banner">
        <div className="banner-content">
          <div className="container">
            <div className="row banner-b justify-content-center">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>About Us</span></h2>
                <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> About Us</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec inner-about">
          <div className="container">
            <div className="row justfy-content-center align-items-center gy-4">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
                <div className="content">
                  {/* <h4 className="theme-h4">Our Story</h4> */}
                  <h2 className="theme-h2"><span>Our</span> Story</h2>
                  <p>At IT Power Systems LLC, we are driven by a vision to revolutionize how businesses connect with the digital landscape. Recognizing the immense potential for small businesses and startups, we harness cutting-edge technology to enhance their online presence and drive growth. With extensive experience and a deep understanding of current digital trends, our team ensures that every client receives industry-leading service.</p>
                  <h4 className="theme-h3"><span>What </span> We Do</h4>
                  <p>We provide a full suite of services tailored to your unique business needs, from graphic design to SEO, web development, and custom software solutions. By blending creativity with advanced technology, we deliver impactful results. Our collaborative approach ensures that we align our strategies with your business goals, driving meaningful outcomes.</p>
                  <h4 className="theme-h3"><span>Our  </span> Commitment</h4>
                  <p>Innovation is at the heart of what we do. At IT Power Systems LLC, we’re not just a service provider; we’re your strategic technology partner. We’re committed to your success, ensuring your digital journey is seamless, powerful, and aligned with your vision.</p>
                  <div className="btns-about">
                    <Link className="theme-btn" to="/our-portfolio">Explore Our Portfolio</Link>
                    {/* <a className="theme-btn-2" href="javascipt:;">Live chat </a> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
                <div className="img-ab">
                  <img src="assets/images/about-second-fold.png" alt="About Us" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ctabanner" >
          <div className="container">
              <div className="box-cta" data-aos="fade-up" data-aos-duration="3000">
              <div className="row justify-content-center align-items-center">
                  <div className="col-12 col-sm-12 col-md-11 col-lg-12 col-xl-11 col-xxl-11">
                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="ctatext">
                      <p>IT Power Systems LLC,</p>
                      <h3>Your business can reach its full potential.</h3>
                      </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="calllsit">
                          <div className=" marginleft">
                          <Link className="theme-btn" to="/contact-us">Contact Us </Link>
                          </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </div>
      </section>
        
        
        </>
 
    
    );
}