import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import SearchEngineOptimization from '../utils/SearchEngineOptimization';
import service1 from '../assets/img/Services/graphicdesign.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Card from './Card';
import { Helmet } from 'react-helmet';


export default function GraphicDesignService(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return(
      <>
      <Helmet>
        <title>IT Power Systems LLC - SEO Services</title>
        <meta name="description" content="Improve your search engine rankings with expert SEO services. We optimize your website for visibility, traffic, and conversions - SEO Services" />
      </Helmet>
      <section className="inner-banner seo-banner">
      <div className="banner-content">
        <div className="container">
          <div className="row banner-b justify-content-center">
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
              <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Search Engine Optimization (SEO) Services </span></h2>
              <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Search Engine Optimization (SEO)</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="about-sec inner-about inner-serv">
        <div className="container">
          <div className="row justify-content-center gy-4">
            <div className="col-xl- col-lg-8 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
              <div>
                <img className='img-fluid' src='assets/images/seo-image.jpg' />
              </div>
              <div className="content"> 
                <h2 className="theme-h2">Search Engine Optimization (SEO)</h2>
                <p>At IT Power Systems LLC, we specialize in delivering top-tier SEO services that improve your website’s visibility and search engine ranking. Our comprehensive SEO strategies are designed to drive organic traffic, enhance user experience, and strengthen your online presence. We focus on keyword research, on-page and off-page optimization, and ongoing performance monitoring to ensure sustained success. </p>
                <p>Our SEO experts collaborate closely with you to develop customized strategies that align with your business goals and target audience. By staying updated on the latest SEO trends and algorithm changes, we help your website remain competitive and achieve long-term growth.</p>
                <h2 className="theme-h2">Services We Provide:</h2>
                <ul>
                  <li>Keyword Research and Strategy</li>
                  <li>On-Page SEO Optimization</li>
                  <li>Off-Page SEO and Link Building</li>
                  <li>Technical SEO Audits</li>
                  <li>SEO Analytics and Reporting</li>
                </ul>
                <h2 className="theme-h2">Why Choose Us:</h2>
                <p>Our team’s deep understanding of SEO algorithms and industry trends ensures that we deliver strategies that drive higher rankings, increased traffic, and improved conversion rates. Trust IT Power Systems LLC to elevate your search engine performance with proven SEO tactics tailored to your business needs.</p>
                <p>Ready to boost your search engine rankings? Contact IT Power Systems LLC today to start optimizing your website for success</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
              <div className="ser-box">
                <img src="assets/images/services-details-icon-01.png" alt="logo design service" className="img-fluid"/>
                <h2 className="theme-h2">Don't hesitate to contact us</h2>
                <p>At our IT solution company, we are committed to exceptional</p>
                <Link className="theme-btn" to="/contact-us">Get In Touch </Link>
               </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing py-5">
        <div className="container">
          <div className="row">
            <div className='text-center pb-5 text-white'>
              <h1 className='pb-2' style={{ fontWeight: 'bold' }}>Choose your right plan!</h1>
              <p className='text-white'>Select from best plan, ensuring a perfect match, Need more or less?</p>
              <p className='text-white pb-2'>Customize Your subscription for a seamless fit!</p>
            </div>
            {
              SearchEngineOptimization?.map((obj) => { return <Card data={obj} /> })   // store data in myPrices map to card 
            }
          </div>
        </div>
      </section>
      <section className="ctabanner" >
          <div className="container">
              <div className="box-cta" data-aos="fade-up" data-aos-duration="3000">
              <div className="row justify-content-center align-items-center">
                  <div className="col-12 col-sm-12 col-md-11 col-lg-12 col-xl-11 col-xxl-11">
                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="ctatext">
                      <p>IT Power Systems LLC,</p>
                      <h3>Your business can reach its full potential.</h3>
                      </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="calllsit">
                          <div className=" marginleft">
                          <Link className="theme-btn" to="/contact-us">Contact Us </Link>
                          </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </div>
      </section>
      </>
    );
}