let myPrices = [
    {
        plan: "Basic",
        price: "$300",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Custom logo design with 2 initial concepts.",
                isEnabled: true
            },
            {
                name: "Professionally designed business cards.",
                isEnabled: true
            },
            {
                name: "Graphics for 3 social media platforms.",
                isEnabled: true
            },
            {
                name: "Limited to 3 revisions only.",
                isEnabled: true
            },
            {
                name: "Design of a company brochure.",
                isEnabled: false
            },
            {
                name: "Design of promotional flyers.",
                isEnabled: false
            },
            {
                name: "Unique illustrations tailored to your brand.",
                isEnabled: false
            },
            {
                name: "In-depth consultation to define your brand identity.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Standard",
        price: "$600",
        recommended: true,
        button: "Get Started",
        features: [
            {
                name: "Custom logo design with 4 initial concepts.",
                isEnabled: true
            },
            {
                name: "Professionally designed business cards.",
                isEnabled: true
            },
            {
                name: "Graphics for 5 social media platforms.",
                isEnabled: true
            },
            {
                name: "Design of a company brochure.",
                isEnabled: true
            },
            {
                name: "Design of promotional flyers.",
                isEnabled: true
            },
            {
                name: "Limited to 6 revisions only.",
                isEnabled: true
            },
            {
                name: "Unique illustrations tailored to your brand.",
                isEnabled: false
            },
            {
                name: "In-depth consultation to define your brand identity.",
                isEnabled: false
            },
            
        ]
    },
    {
        plan: "Premium",
        price: "$1,200",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Custom logo design with 8 initial concepts.",
                isEnabled: true
            },
            {
                name: "Professionally designed business cards.",
                isEnabled: true
            },
            {
                name: "Graphics for unlimited social media platforms.",
                isEnabled: true
            },
            {
                name: "Design of a company brochure.",
                isEnabled: true
            },
            {
                name: "Design of promotional flyers.",
                isEnabled: true
            },
            {
                name: "Unlimited revisions until satisfaction.",
                isEnabled: true
            },
            {
                name: "Unique illustrations tailored to your brand.",
                isEnabled: true
            },
            {
                name: "In-depth consultation to define your brand identity.",
                isEnabled: true
            },
        ]
    }
]

export default myPrices;