let DigitalMarketing = [
    {
        plan: "Basic",
        price: "$800/month",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "01 channels (Google).",
                isEnabled: true
            },
            {
                name: "Optimizing your website for better search visibility.",
                isEnabled: true
            },
            {
                name: "Running basic PPC campaigns to drive traffic.",
                isEnabled: true
            },
            {
                name: "Creating and sending email newsletters.",
                isEnabled: true
            },
            {
                name: "Up to $500/month ad spend management.",
                isEnabled: true
            },
            {
                name: "Basic social media management and content creation.",
                isEnabled: true
            },
            {
                name: "Weekly performance report with basic analysis.",
                isEnabled: true
            },
            {
                name: "Improving your website's conversion rate.",
                isEnabled: false
            },
            {
                name: "Creating and promoting valuable content.",
                isEnabled: false
            },
            {
                name: "Monthly market research and trends report.",
                isEnabled: false
            },
            {
                name: "Ad copywriting and graphic design.",
                isEnabled: false
            }, 
            {
                name: "In-depth analysis and reporting.",
                isEnabled: false
            },
            {
                name: "Generating and nurturing leads to convert them into customers.",
                isEnabled: false
            },
            {
                name: "In-depth competitor analysis and benchmarking.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Standard",
        price: "$1,500/month",
        recommended: true,
        button: "Get Started",
        features: [
            {
                name: "02 channels (Google, Facebook).",
                isEnabled: true
            },
            {
                name: "Optimizing your website for better search visibility.",
                isEnabled: true
            },
            {
                name: "Running advanced PPC campaigns to drive traffic.",
                isEnabled: true
            },
            {
                name: "Creating and sending email newsletters.",
                isEnabled: true
            },
            {
                name: "Up to $1000/month ad spend management.",
                isEnabled: true
            },
            {
                name: "Advanced social media management and content creation.",
                isEnabled: true
            },
            {
                name: "Weekly performance report with basic analysis.",
                isEnabled: true
            },
            {
                name: "Improving your website's conversion rate.",
                isEnabled: true
            },
            {
                name: "Creating and promoting valuable content.",
                isEnabled: true
            },
            {
                name: "Monthly market research and trends report.",
                isEnabled: true
            },
            {
                name: "Ad copywriting and graphic design.",
                isEnabled: true
            }, 
            {
                name: "In-depth analysis and reporting.",
                isEnabled: false
            },
            {
                name: "Generating and nurturing leads to convert them into customers.",
                isEnabled: false
            },
            {
                name: "In-depth competitor analysis and benchmarking.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Premium",
        price: "$3,000/month",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "3 or more channels (Google, Facebook, LinkedIn).",
                isEnabled: true
            },
            {
                name: "Optimizing your website for better search visibility.",
                isEnabled: true
            },
            {
                name: "Running advanced PPC campaigns to drive traffic.",
                isEnabled: true
            },
            {
                name: "Creating and sending email newsletters.",
                isEnabled: true
            },
            {
                name: "Up to $2000/month ad spend management.",
                isEnabled: true
            },
            {
                name: "Advanced social media management and content creation.",
                isEnabled: true
            },
            {
                name: "Weekly performance report with detailed analysis.",
                isEnabled: true
            },
            {
                name: "Improving your website's conversion rate.",
                isEnabled: true
            },
            {
                name: "Creating and promoting valuable content.",
                isEnabled: true
            },
            {
                name: "Monthly market research and trends report.",
                isEnabled: true
            },
            {
                name: "Full ad copywriting, graphic design, and video.",
                isEnabled: true
            },
            {
                name: "In-depth analysis and reporting.",
                isEnabled: true
            },
            {
                name: "Generating and nurturing leads to convert them into customers.",
                isEnabled: true
            },
            {
                name: "In-depth competitor analysis and benchmarking.",
                isEnabled: true
            },
        ]
    }
]

export default DigitalMarketing;