import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import MobileApp from '../utils/MobileApp';
import service1 from '../assets/img/Services/graphicdesign.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Card from './Card';
import { Helmet } from 'react-helmet';

export default function MobileAppServices(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return(
      <>
      <Helmet>
        <title>IT Power Systems LLC - Mobile App Development</title>
        <meta name="description" content="Engage your customers with custom mobile app development. We create innovative apps for iOS and Android that enhance user experience and drive engagement - Mobile App Development" />
      </Helmet>
      <section className="inner-banner mobile-app">
        <div className="banner-content">
          <div className="container">
            <div className="row banner-b justify-content-center">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Mobile App Development</span></h2>
                <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Mobile App Development</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    <section className="about-sec inner-about inner-serv">
        <div className="container">
          <div className="row justify-content-center gy-4">
            <div className="col-xl- col-lg-8 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
              <div>
                <img className='img-fluid' src='assets/images/mobile-app-image.jpg' />
              </div>
              <div className="content"> 
                <h2 className="theme-h2">Mobile App Development Services </h2>
                <p>At IT Power Systems LLC, we provide comprehensive mobile app development services designed to help businesses connect with their audience on the go. We specialize in creating custom mobile apps that are functional, user-friendly, and aligned with your business objectives. Our expertise spans both iOS and Android platforms, ensuring broad reach and accessibility for your target users.</p>
                <p>Our client-focused development process starts with understanding your unique needs and goals. We then design, develop, and deploy mobile apps that offer seamless user experiences and drive engagement. With IT Power Systems LLC, your mobile app ideas are transformed into powerful tools for business growth.</p>
                <h2 className="theme-h2">Services We Provide:</h2>
                <ul>
                  <li>iOS App Development</li>
                  <li>Android App Development</li>
                  <li>Cross-Platform App Development</li>
                  <li>App UI/UX Design</li>
                  <li>App Maintenance and Updates</li>
                </ul>
                <h2 className="theme-h2">Why We Are the Best: </h2>
                <p>We excel in mobile app development by combining innovative design with advanced technology. Our experienced developers work closely with you to ensure your app meets all your business requirements and exceeds user expectations. From concept to launch, we are your trusted partner in mobile app success.</p>
                <p>Bring your mobile app vision to reality with IT Power Systems LLC. Contact us today to start your app development journey.</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
              <div className="ser-box">
                <img src="assets/images/services-details-icon-01.png" alt="logo design service" className="img-fluid"/>
                <h2 className="theme-h2">Don't hesitate to contact us</h2>
                <p>At our IT solution company, we are committed to exceptional</p>
                <Link className="theme-btn" to="/contact-us">Get In Touch </Link>
               </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing py-5">
        <div className="container">
          <div className="row">
            <div className='text-center pb-5 text-white'>
              <h1 className='pb-2' style={{ fontWeight: 'bold' }}>Choose your right plan!</h1>
              <p className='text-white'>Select from best plan, ensuring a perfect match, Need more or less?</p>
              <p className='text-white pb-2'>Customize Your subscription for a seamless fit!</p>
            </div>
            {
              MobileApp?.map((obj) => { return <Card data={obj} /> })   // store data in myPrices map to card 
            }
          </div>
        </div>
      </section>
      <section className="ctabanner" >
          <div className="container">
              <div className="box-cta" data-aos="fade-up" data-aos-duration="3000">
              <div className="row justify-content-center align-items-center">
                  <div className="col-12 col-sm-12 col-md-11 col-lg-12 col-xl-11 col-xxl-11">
                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="ctatext">
                      <p>IT Power Systems LLC,</p>
                      <h3>Your business can reach its full potential.</h3>
                      </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="calllsit">
                          <div className=" marginleft">
                          <Link className="theme-btn" to="/contact-us">Contact Us </Link>
                          </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </div>
      </section>
      </>
    );
}