let SearchEngineOptimization = [
    {
        plan: "Basic",
        price: "$500/month",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Site audit with comprehensive keyword research.",
                isEnabled: true
            },
            {
                name: "Optimizing individual pages for better rankings.",
                isEnabled: true
            },
            {
                name: "03 optimized blogs content creation.",
                isEnabled: true
            },
            {
                name: "Building backlinks and improving off-site SEO.",
                isEnabled: true
            },
            {
                name: "Comprehensive audit of technical SEO factors.",
                isEnabled: false
            },
            {
                name: "Creating quality backlinks to boost your rankings.",
                isEnabled: false
            },
            {
                name: "Optimizing for local search results.",
                isEnabled: false
            },
            {
                name: "Optimizing for Google My Business.",
                isEnabled: false
            },
            {
                name: "Specialized SEO for e-commerce websites.",
                isEnabled: false
            },
            {
                name: "In-depth competitor analysis with monthly benchmarking.",
                isEnabled: false
            },
            {
                name: "Detailed monthly reports on SEO performance.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Standard",
        price: "$1,000/month",
        recommended: true,
        button: "Get Started",
        features: [
            {
                name: "Site audit with comprehensive keyword research.",
                isEnabled: true
            },
            {
                name: "Optimizing individual pages for better rankings.",
                isEnabled: true
            },
            {
                name: "06 optimized blogs content creation.",
                isEnabled: true
            },
            {
                name: "Building backlinks and improving off-site SEO.",
                isEnabled: true
            },
            {
                name: "Comprehensive audit of technical SEO factors.",
                isEnabled: true
            },
            {
                name: "Creating quality backlinks to boost your rankings.",
                isEnabled: true
            },
            {
                name: "Optimizing for local search results.",
                isEnabled: true
            },
            {
                name: "Optimizing for Google My Business.",
                isEnabled: true
            },
            {
                name: "Specialized SEO for e-commerce websites.",
                isEnabled: false
            },
            {
                name: "In-depth competitor analysis with monthly benchmarking.",
                isEnabled: false
            },
            {
                name: "Detailed monthly reports on SEO performance.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Premium",
        price: "$2000/month",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Site audit with advanced keyword research.",
                isEnabled: true
            },
            {
                name: "Optimizing individual pages for better rankings.",
                isEnabled: true
            },
            {
                name: "10 optimized blogs content creation.",
                isEnabled: true
            },
            {
                name: "Building backlinks and improving off-site SEO.",
                isEnabled: true
            },
            {
                name: "Comprehensive audit of technical SEO factors.",
                isEnabled: true
            },
            {
                name: "Creating quality backlinks to boost your rankings.",
                isEnabled: true
            },
            {
                name: "Optimizing for local search results.",
                isEnabled: true
            },
            {
                name: "Optimizing for Google My Business.",
                isEnabled: true
            },
            {
                name: "Specialized SEO for e-commerce websites.",
                isEnabled: true
            },
            {
                name: "In-depth competitor analysis with monthly benchmarking.",
                isEnabled: true
            },
            {
                name: "Detailed monthly reports on SEO performance.",
                isEnabled: true
            },
        ]
    }
]

export default SearchEngineOptimization;