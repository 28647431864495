let MobileApp = [
    {
        plan: "Basic",
        price: "$3,000",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Simple mobile app with core functionalities.",
                isEnabled: true
            },
            {
                name: "Basic design and prototyping.",
                isEnabled: true
            },
            {
                name: "Responsive Android or ios app.",
                isEnabled: true
            },
            {
                name: "Basic user authentication features.",
                isEnabled: true
            },
            {
                name: "Sending notifications to users through the app.",
                isEnabled: false
            },
            {
                name: "Integrating APIs for added functionality.",
                isEnabled: false
            },
            {
                name: "Integrating custom animations and transitions.",
                isEnabled: false
            },
            {
                name: "Adding features for in-app purchases.",
                isEnabled: false
            },
            {
                name: "Ongoing support and maintenance after launch.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Standard",
        price: " $6,000",
        recommended: true,
        button: "Get Started",
        features: [
            {
                name: "Enterprise-level mobile app with basic features.",
                isEnabled: true
            },
            {
                name: "Advanced design and prototyping.",
                isEnabled: true
            },
            {
                name: "Responsive Android and ios app.",
                isEnabled: true
            },
            {
                name: "Advanced user authentication features.",
                isEnabled: true
            },
            {
                name: "Sending notifications to users through the app.",
                isEnabled: true
            },
            {
                name: "Integrating APIs for added functionality.",
                isEnabled: true
            },
            {
                name: "Integrating custom animations and transitions.",
                isEnabled: false
            },
            {
                name: "Adding features for in-app purchases.",
                isEnabled: false
            },
            {
                name: "Ongoing support and maintenance after launch.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Premium",
        price: "$12,000",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Enterprise-level mobile app with complex features.",
                isEnabled: true
            },
            {
                name: "Advanced design and prototyping.",
                isEnabled: true
            },
            {
                name: "Responsive Android and ios app.",
                isEnabled: true
            },
            {
                name: "Advanced user authentication features.",
                isEnabled: true
            },
            {
                name: "Sending notifications to users through the app.",
                isEnabled: true
            },
            {
                name: "Integrating APIs for added functionality.",
                isEnabled: true
            },
            {
                name: "Integrating custom animations and transitions.",
                isEnabled: true
            },
            {
                name: "Adding features for in-app purchases.",
                isEnabled: true
            },
            {
                name: "Ongoing support and maintenance after launch.",
                isEnabled: true
            },
        ]
    }
]

export default MobileApp;