let UiUxDesign = [
    {
        plan: "Basic",
        price: "$700",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Creative Ui design upto 15 screens.",
                isEnabled: true
            },
            {
                name: "Basic user research to inform design decisions.",
                isEnabled: true
            },
            {
                name: "Creating a detailed wireframing for the design.",
                isEnabled: true
            },
            {
                name: "Designing the user interface based on wireframes.",
                isEnabled: true
            },
            {
                name: "Creating a working prototype for testing.",
                isEnabled: false
            },
            {
                name: "Conducting user testing to ensure usability.",
                isEnabled: false
            },
            {
                name: "Ongoing consultation to refine user experience.",
                isEnabled: false
            },
            {
                name: "Designing and integrating custom animations.",
                isEnabled: false
            },
            {
                name: "Advanced competitive research and analysis.",
                isEnabled: false
            },
            {
                name: "Providing ongoing support and updates.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Standard",
        price: "$1,500",
        recommended: true,
        button: "Get Started",
        features: [
            {
                name: "Creative Ui design upto 30 screens.",
                isEnabled: true
            },
            {
                name: "Detailed user research to inform design decisions.",
                isEnabled: true
            },
            {
                name: "Creating a detailed wireframing for the design.",
                isEnabled: true
            },
            {
                name: "Designing the user interface based on wireframes.",
                isEnabled: true
            },
            {
                name: "Creating a working prototype for testing.",
                isEnabled: true
            },
            {
                name: "Conducting user testing to ensure usability.",
                isEnabled: true
            },
            {
                name: "Ongoing consultation to refine user experience.",
                isEnabled: false
            },
            {
                name: "Designing and integrating custom animations.",
                isEnabled: false
            },
            {
                name: "Advanced competitive research and analysis.",
                isEnabled: false
            },
            {
                name: "Providing ongoing support and updates.",
                isEnabled: false
            },
        ]
    },
    {
        plan: "Premium",
        price: "$3,000",
        recommended: false,
        button: "Get Started",
        features: [
            {
                name: "Creative Ui design unlimited screens.",
                isEnabled: true
            },
            {
                name: "Advanced user research to inform design decisions.",
                isEnabled: true
            },
            {
                name: "Creating a detailed wireframing for the design.",
                isEnabled: true
            },
            {
                name: "Designing the user interface based on wireframes.",
                isEnabled: true
            },
            {
                name: "Creating a working prototype for testing.",
                isEnabled: true
            },
            {
                name: "Conducting user testing to ensure usability.",
                isEnabled: true
            },
            {
                name: "Ongoing consultation to refine user experience.",
                isEnabled: true
            },
            {
                name: "Designing and integrating custom animations.",
                isEnabled: true
            },
            {
                name: "Advanced competitive research and analysis.",
                isEnabled: true
            },
            {
                name: "Providing ongoing support and updates.",
                isEnabled: true
            },
        ]
    }
]

export default UiUxDesign;