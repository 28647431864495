import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import SoftwareDevelopments from '../utils/SoftwareDevelopments';
import service1 from '../assets/img/Services/graphicdesign.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Card from './Card';
import { Helmet } from 'react-helmet';

export default function SoftwareDevelopment(){
      useEffect(() => {
        // Initialize AOS
        AOS.init();
    }, []);
    return(
      <>
      <Helmet>
        <title>IT Power Systems LLC - Software Development</title>
        <meta name="description" content="Drive efficiency with custom software development. Our tailored solutions meet your business needs and ensure seamless operations - Software Development" />
      </Helmet>
      <section className="inner-banner software">
        <div className="banner-content">
          <div className="container">
            <div className="row banner-b justify-content-center">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Software Development</span></h2>
                <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Software Development</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    <section className="about-sec inner-about inner-serv">
        <div className="container">
          <div className="row justify-content-center gy-4">
            <div className="col-xl- col-lg-8 col-md-12 col-sm-12 col-12" data-aos="fade-right" data-aos-duration="3000">
              <div>
                <img className='img-fluid' src='assets/images/software-image.jpg' />
              </div>
              <div className="content"> 
                <h2 className="theme-h2">Software Development Services</h2>
                <p>At IT Power Systems LLC, we specialize in custom software development designed to meet the unique needs of your business. Our development process is focused on delivering robust, scalable, and efficient software solutions that enhance operational efficiency and drive business growth. Whether you need enterprise software solutions or API development, we tailor our services to support your specific goals.</p>
                <p>Our experienced developers use the latest technologies and methodologies to provide high-quality deliverables. From new software solutions to upgrades and integrations, we offer end-to-end software development services that propel your business forward.</p>
                <h2 className="theme-h2">Services We Provide:</h2>
                <ul>
                  <li>Custom Software Development</li>
                  <li>Enterprise Software Solutions</li>
                  <li>Software Integration Services</li>
                  <li>API Development</li>
                  <li>Software Maintenance and Support</li>
                </ul>
                <h2 className="theme-h2">Why We Are the Best: </h2>
                <p>We combine technical expertise with a deep understanding of business processes to develop software that delivers results. Our agile approach ensures timely delivery and flexibility, allowing your software to evolve with your business. Partner with IT Power Systems LLC for software solutions that drive success.</p>
                <p>Transform your business with our expert software development services. Contact IT Power Systems LLC today to discuss your project.</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" data-aos="fade-left" data-aos-duration="3000">
              <div className="ser-box">
                <img src="assets/images/services-details-icon-01.png" alt="logo design service" className="img-fluid"/>
                <h2 className="theme-h2">Don't hesitate to contact us</h2>
                <p>At our IT solution company, we are committed to exceptional</p>
                <Link className="theme-btn" to="/contact-us">Get In Touch </Link>
               </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing py-5">
        <div className="container">
          <div className="row">
            <div className='text-center pb-5 text-white'>
              <h1 className='pb-2' style={{ fontWeight: 'bold' }}>Choose your right plan!</h1>
              <p className='text-white'>Select from best plan, ensuring a perfect match, Need more or less?</p>
              <p className='text-white pb-2'>Customize Your subscription for a seamless fit!</p>
            </div>
            {
              SoftwareDevelopments?.map((obj) => { return <Card data={obj} /> })   // store data in myPrices map to card 
            }
          </div>
        </div>
      </section>
      <section className="ctabanner" >
          <div className="container">
              <div className="box-cta" data-aos="fade-up" data-aos-duration="3000">
              <div className="row justify-content-center align-items-center">
                  <div className="col-12 col-sm-12 col-md-11 col-lg-12 col-xl-11 col-xxl-11">
                  <div className="row align-items-center">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="ctatext">
                      <p>IT Power Systems LLC,</p>
                      <h3>Your business can reach its full potential.</h3>
                      </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                      <div className="calllsit">
                          <div className=" marginleft">
                          <Link className="theme-btn" to="/contact-us">Contact Us </Link>
                          </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </div>
      </section>
      </>
    );
}